import React from "react";
import CIcon from "@material-ui/icons/VpnKey";

import {
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    List,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
} from 'react-admin';
import {PostPagination} from "./Channel";
import {TimeField} from "./common/TimeField";

export const YoutubeKeyIcon = CIcon;

const Filters = (props) => (
    <Filter {...props}>
        <TextInput source={"key"} label={"Search"} alwaysOn/>
    </Filter>
);


const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
        />
    </Toolbar>
);

export const YoutubeKeyList = (props) => (
    <List {...props} filters={<Filters/>}
          perPage={25}
          bulkActionButtons={false} pagination={<PostPagination/>}>
        <Datagrid>
            <TextField source={'key'}/>
            <TextField source={'useCount'}/>
            <TextField source={'failCount'}/>
            <TimeField source={"time"}/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const YoutubeKeyCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const YoutubeKeyEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CreateOrEdit = (props) => (
    <SimpleForm {...props} toolbar={<EditToolbar/>} redirect={'list'}>
        <TextInput source={"key"} helperText={"Input youtube API key"}/>
        <TextInput source={"description"}
                   fullWidth
                   multline
                   helperText={"Something so you remember from where you got this key etc"}/>
        <TextInput source={"useCount"} disabled/>
        <TextInput source={"failCount"} disabled/>

    </SimpleForm>
);