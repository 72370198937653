import React from "react";
import Icon from '@material-ui/icons/MonetizationOn';

import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";

export const AdUnitIcon = Icon;

export const AdUnitsList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <ReferenceField source={"country"} reference={"countries"}>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source={"bannerAd"}/>
            <TextField source={"interstitialAd"}/>
            <BooleanField source={'active'}/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const AdUnitCreate = props => (<Create {...props}><CreateOrEdit/></Create>)

export const AdUnitEdit = props => (<Edit {...props}><CreateOrEdit/></Edit>)

const CreateOrEdit = props => (
    <SimpleForm {...props} redirect={'list'}>
        <ReferenceInput source={"country"}
                        reference={"countries"}
                        validate={required()}
                        filterToQuery={searchText => ({name: searchText})}>
            <AutocompleteInput optionText={"name"}
                               options={{fullWidth: false, style: {marginBottom: '0.5em'}}}/>
        </ReferenceInput>
        <TextInput source={'bannerAd'} validate={required()}/>
        <TextInput source={'interstitialAd'} validate={required()}/>
        <BooleanInput source={'active'}/>
    </SimpleForm>
);