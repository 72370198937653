import React from 'react';
import {Admin, Resource} from 'react-admin';
import OurClient from "./rest/OurClient";
import AuthClient from "./login/AuthClient";
import {SERVER_IP} from "./index";

import Login from "./login/Login";
import {ChannelCreate, ChannelEdit, ChannelList, ShowChannel} from "./Channel";
import Layout from "./Layout";
import {CountryCreate, CountryEdit, CountryIcon, CountryList, LanguageIcon, RegionIcon} from "./Country";
import {TimezoneCreate, TimezoneEdit, TimezoneIcon, TimezoneList} from "./Timezone";
import {NotificationCreate, NotificationIcon, NotificationList, NotificationLogList} from "./Notification";
import {YoutubeKeyCreate, YoutubeKeyEdit, YoutubeKeyIcon, YoutubeKeyList} from "./YoutubeKey";
import {UserDataIcon, UserDataList} from "./UserData";
import {AdUnitCreate, AdUnitEdit, AdUnitIcon, AdUnitsList} from "./AdUnits";
import LogIcon from "@material-ui/icons/Traffic";
import {DataUpdateIcon, DataUpdateList, DataUpdateShow} from "./data/DataUpdate";
import {RssChannelCreate, RssChannelEdit, RssChannelList} from "./RssChannel";

class App extends React.Component {
    render() {
        return (
            <Admin dataProvider={OurClient(SERVER_IP)}
                   authProvider={AuthClient}
                   loginPage={Login}
                   layout={Layout}
                   title={"News Channel Manager"}>
                <Resource name={"channels"}
                          list={ChannelList}
                          edit={ChannelEdit}
                          show={ShowChannel}
                          create={ChannelCreate}
                />
                <Resource name={"rssChannels"}
                          list={RssChannelList}
                          edit={RssChannelEdit}
                          options={{label: "RSS Channels"}}
                          create={RssChannelCreate}
                />
                <Resource name={"languages"}
                          list={CountryList}
                          icon={LanguageIcon}
                          edit={CountryEdit}
                          create={CountryCreate}/>
                <Resource name={"countries"}
                          list={CountryList}
                          edit={CountryEdit}
                          icon={CountryIcon}
                          create={CountryCreate}/>
                <Resource name={"regions"}
                          list={CountryList}
                          edit={CountryEdit}
                          icon={RegionIcon}
                          create={CountryCreate}/>
                <Resource name={"timezones"}
                          icon={TimezoneIcon}
                          list={TimezoneList}
                          create={TimezoneCreate} fi
                          edit={TimezoneEdit}/>
                <Resource name={'notifications'}
                          icon={NotificationIcon}
                          list={NotificationList}
                          create={NotificationCreate}/>
                <Resource name={'keys'}
                          icon={YoutubeKeyIcon}
                          create={YoutubeKeyCreate}
                          edit={YoutubeKeyEdit}
                          list={YoutubeKeyList}/>
                <Resource list={UserDataList}
                          name={'userData'}
                          options={{label: "Users"}}
                          icon={UserDataIcon}
                />

                <Resource list={AdUnitsList}
                          name={'adUnits'}
                          icon={AdUnitIcon}
                          create={AdUnitCreate}
                          edit={AdUnitEdit}
                />
                <Resource name={'notificationLogs'}
                          options={{label: "Notification Logs"}}
                          list={NotificationLogList} icon={LogIcon}/>
                <Resource name={'dataUpdates'}
                          options={{label: "Data Updates"}}
                          show={DataUpdateShow}
                          list={DataUpdateList}
                          icon={DataUpdateIcon}/>
                <Resource name={'videos'}/>

            </Admin>
        )
    }
}


export default App;
