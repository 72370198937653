import React from "react";
import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormTab,
    List,
    NumberField,
    Pagination,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import FullnameField from "./FullnameField";
import RichTextInput from 'ra-input-rich-text';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import {fetchJson} from "./rest/fetch";
import {SERVER_IP} from "./index";

const Filters = props => (
    <Filter {...props}>
        <TextInput source={"id"} alwaysOn/>
        <TextInput source="title" alwaysOn/>
        <TextInput source="language"/>
        <TextInput source="country"/>
        <TextInput source="region"/>
        <TextInput source="url"/>
    </Filter>
);
export const PostPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100, 150, 200]} {...props} />;
export const ChannelList = props => (
    <List {...props} filters={<Filters/>} perPage={25} bulkActionButtons={false} pagination={<PostPagination/>}>
        <Datagrid rowClick="edit">
            <FullnameField/>
            <TextField source="url"/>
            <TextField source={"website"}/>
            <ReferenceField source={"languageId"} reference={"languages"}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source={"countryId"} reference={"countries"}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source={"regionId"} reference={"regions"}>
                <TextField source="name"/>
            </ReferenceField>

            <BooleanField source="live"/>
            <BooleanField source="demand"/>
            <BooleanField source="top"/>
            <NumberField source="viewCount"/>
            <ShowButton/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const ShowChannel = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={"General"}>
                <FullnameField/>
                <TextField source="url"/>
                <ReferenceField source={"languageId"} reference={"languages"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source={"countryId"} reference={"countries"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source={"regionId"} reference={"regions"}>
                    <TextField source="name"/>
                </ReferenceField>

                <BooleanField source="live"/>
                <BooleanField source="demand"/>
                <BooleanField source="top"/>
                <NumberField source="viewCount"/>
            </Tab>
            <Tab label={"Videos"}>
                <ReferenceManyField reference="videos"
                                    target="channelId"
                                    sort={{'publishedAt': "DESC"}}
                                    pagination={<PostPagination/>}
                                    addLabel={false}>
                    <Datagrid>
                        <TextField source="videoId"/>
                        <TextField source={"videoTitle"}/>
                        <DateField source={"publishedAt"} showTime/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label={"Contact"}>
                <TextField source={"website"}/>
                <TextField source={"phone"}/>
                <TextField source={"email"}/>
                <TextField source={"address"}/>
                <TextField source={"facebook"}/>
                <TextField source={"twitter"}/>
                <TextField source={"wikipedia"}/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const ChannelEdit = props => (
    <Edit {...props} aside={<Aside/>}>
        <EditOrCreate {...props}/>
    </Edit>
);
export const ChannelCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="url" validate={required()}/>
        </SimpleForm>
    </Create>
);
const EditOrCreate = (props) => (
    <TabbedForm {...props}>
        <FormTab label={"General"}>
            <TextInput source="id" disabled/>
            <TextInput source="url" validate={required()} disabled/>
            <TextInput source={"subscriberCount"} disabled/>
            <TextInput source={"viewCount"} disabled/>
            <TextInput source={"videoCount"} disabled/>
            <TextInput source="title" validate={required()}/>
            <TextInput source={"customUrl"} disabled/>
            <ReferenceInput source={"languageId"}
                            reference={"languages"}
                            validate={required()}
                            filterToQuery={searchText => ({name: searchText})}>
                <AutocompleteInput optionText={"name"}
                                   options={{fullWidth: false, style: {marginBottom: '0.5em'}}}/>
            </ReferenceInput>

            <ReferenceInput source={"countryId"}
                            reference={"countries"}
                            validate={required()}
                            filterToQuery={searchText => ({name: searchText})}>
                <AutocompleteInput optionText={"name"}
                                   options={{fullWidth: false, style: {marginBottom: '0.5em'}}}/>
            </ReferenceInput>

            <ReferenceInput source={"regionId"}
                            reference={"regions"}
                            validate={required()}
                            filterToQuery={searchText => ({name: searchText})}>
                <AutocompleteInput optionText={"name"}
                                   options={{fullWidth: false, style: {marginBottom: '0.5em'}}}/>
            </ReferenceInput>

            <BooleanInput source={"live"}/>
            <BooleanInput source={"demand"}/>
            <BooleanInput source={"top"}/>

            <RichTextInput source={"description"}/>
        </FormTab>
        <FormTab label={"Contact"}>
            <TextInput source={"website"}/>
            <TextInput source={"phone"}/>
            <TextInput source={"email"}/>
            <TextInput source={"facebook"}/>
            <TextInput source={"twitter"}/>
            <TextInput source={"wikipedia"}/>
            <TextInput source={"address"} fullWidth multline/>
        </FormTab>
    </TabbedForm>
);

class Aside extends React.Component {
    state = {
        loading: false,
        live: null,
    };
    checkLive = (id) => {
        this.setState({loading: true});
        fetchJson(`${SERVER_IP}/channels/checkLive/${id}`, {method: "POST"})
            .then(json => {
                return JSON.parse(json.body)
            })
            .then(({code}) => {
                this.setState({loading: false, live: code === 200});
            })
            .catch((e) => {
                console.log(e);
                this.setState({live: false, loading: false})
            })
    };

    render() {
        const {record} = this.props;
        const {loading, live} = this.state;
        return <div style={{width: 350, margin: '1em'}}>
            {record &&
            <Button color={'secondary'} variant={'text'} onClick={() => this.checkLive(record.id)}>
                Check Live
                {loading && <CircularProgress size={20}/>}
            </Button>
            }
            {live !== null &&
            <Typography variant="h5">{live === false ? "Channel is not live" : "Channel is live"}</Typography>}

        </div>
    }
}

