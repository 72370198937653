import {connect} from 'react-redux';
import {Layout} from 'react-admin';
import React from "react";

export const lightTheme = {
    palette: {
        secondary: {
            light: '#428e92',
            main: '#006064',
            dark: '#00363a',
            contrastText: '#fff',

        },
        primary: {
            light: '#428e92',
            main: '#006064',
            dark: '#00363a',
            contrastText: '#fff',
        },
    },
};
const CustomLayout = props => (
    <Layout {...props}/>
);
export default connect(
    () => ({
        theme: lightTheme
    }),
    {}
)(CustomLayout);