import React from "react";
import CIcon from "@material-ui/icons/Update";

import {
    Datagrid,
    DateField,
    List,
    ReferenceManyField,
    Show,
    ShowButton,
    Tab,
    TabbedShowLayout,
    TextField,
} from 'react-admin';
import {PostPagination} from "../Channel";
import FullnameField from "../FullnameField";
import {DataUpdateListActions} from "./DataUpdateListActions";

export const DataUpdateIcon = CIcon;



export const DataUpdateList = (props) => (
    <List {...props}
          perPage={25}
          sort={{field:"ranAt",order:"DESC"}}
          actions={<DataUpdateListActions/>}
          bulkActionButtons={false} pagination={<PostPagination/>}>
        <Datagrid>
            <DateField source={"ranAt"} showTime/>
            <DateField source={"finishedAt"} showTime/>
            <TextField source={"total"}/>
            <TextField source={"failed"}/>
            <TextField source={"withRss"}/>
            <TextField source={"withScrap"}/>
            <TextField source={"withApi"}/>
            <TextField source={"trigger"}/>
            <ShowButton/>
        </Datagrid>
    </List>
);

export const DataUpdateShow=props=>(
  <Show {...props}>
      <TabbedShowLayout>
          <Tab label={"General"}>
              <DateField source={"ranAt"} showTime/>
              <DateField source={"finishedAt"} showTime/>
              <TextField source={"total"}/>
              <TextField source={"failed"}/>
              <TextField source={"withRss"}/>
              <TextField source={"withScrap"}/>
              <TextField source={"withApi"}/>
              <TextField source={"trigger"}/>
          </Tab>
          <Tab label={"Failed Channels"}>
              <ReferenceManyField reference="channels"
                                  target="channelId"
                                  pagination={<PostPagination/>}
                                  addLabel={false}>
                  <Datagrid>
                      <FullnameField/>
                      <TextField source="url"/>
                  </Datagrid>
              </ReferenceManyField>
          </Tab>
      </TabbedShowLayout>
  </Show>
);
