import React from "react";
import {
    AutocompleteInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormTab,
    List,
    Pagination,
    ReferenceField,
    ReferenceInput,
    required,
    ShowButton,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';

const Filters = props => (
    <Filter {...props}>
        <TextInput source="title" alwaysOn/>
        <TextInput source="countryId"/>
    </Filter>
);
export const PostPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100, 150, 200]} {...props} />;
export const RssChannelList = props => (
    <List {...props} filters={<Filters/>} perPage={25} bulkActionButtons={false} pagination={<PostPagination/>}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
            <TextField source={"rssLink"}/>
            <TextField source={"category"}/>
            <ReferenceField source={"countryId"} reference={"countries"}>
                <TextField source="name"/>
            </ReferenceField>
            <ShowButton/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);


export const RssChannelEdit = props => (
    <Edit {...props}>
        <EditOrCreate {...props}/>
    </Edit>
);
export const RssChannelCreate = props => (
    <Create {...props}>
        <EditOrCreate/>
    </Create>
);
const EditOrCreate = (props) => (
    <TabbedForm {...props}>
        <FormTab label={"General"}>
            <TextInput source="rssLink" validate={required()}/>
            <TextInput source="title" validate={required()}/>
            <TextInput source={'category'} validate={required()}/>
            <ReferenceInput source={"countryId"}
                            reference={"countries"}
                            validate={required()}
                            filterToQuery={searchText => ({name: searchText})}>
                <AutocompleteInput optionText={"name"}
                                   options={{fullWidth: false, style: {marginBottom: '0.5em'}}}/>
            </ReferenceInput>

        </FormTab>
    </TabbedForm>
);
