import React from "react";
import CIcon from "@material-ui/icons/Timelapse";

import {
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    List,
    NumberInput,
    required,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
} from 'react-admin';
import {PostPagination} from "./Channel";

export const TimezoneIcon = CIcon;

const Filters = (props) => (
    <Filter {...props}>
        <TextInput source={"name"} label={"Search"} alwaysOn/>
    </Filter>
);


const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
        />
    </Toolbar>
);

export const TimezoneList = (props) => (
    <List {...props} filters={<Filters/>}
          perPage={25}
          bulkActionButtons={false} pagination={<PostPagination/>}>
        <Datagrid>
            <TextField source={"name"}/>
            <TextField source={"hours"}/>
            <TextField source={"minutes"}/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const TimezoneCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);
export const TimezoneEdit = (props) => (
    <Edit {...props} >
        <CreateOrEdit/>
    </Edit>
);
const CreateOrEdit = (props) => (
    <SimpleForm {...props} toolbar={<EditToolbar/>} redirect={'list'}>
        <TextInput source={"name"} validate={required()}/>
        <NumberInput source={"hours"}
                     helperText="Hours deviation from UTC e.g 5 for India"
                     validate={required()}/>
        <NumberInput source={"minutes"} validate={required()}
                     helperText="Minutes deviation from UTC e.g 45 for India"/>
    </SimpleForm>
);