import React from 'react';
import AvatarField from './AvatarField';

const FullNameField = ({record = {}, size}) => (
    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
        <AvatarField record={record} size={size}/>
        &nbsp;<b>{record.title}</b>
    </div>
);


export default FullNameField;