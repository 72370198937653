import React from "react";
import Chip from "@material-ui/core/Chip";

export const TimeField = ({record, source}) => {
    if (record[source] === 0 || record[source] === undefined) {
        return <Chip label={"NA"}/>
    } else {
        let date = new Date(record[source]);
        return <Chip label={date.toLocaleString()}/>;
    }
};

