import React, {Fragment, useState} from "react";
import {Button} from 'react-admin'
import {Dialog, DialogActions, MuiThemeProvider} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SERVER_IP} from "../index";
import {fetchJson} from "../rest/fetch";
import {lightTheme} from "../Layout";
import MaterialButton from '@material-ui/core/Button'
import CIcon from "@material-ui/icons/Update";


function ManualUpdateButton() {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("");

    function handleClose() {
        setOpen(false)
    }


    function saveConfig() {
        setLoading(true)
        fetchJson(`${SERVER_IP}/dataUpdates/run`,
            {method: "POST"})
            .then(() => {
                setOpen(false)
                setMessage("Update triggered successfully.")
            })
            .catch(error => {
                setMessage("An update is already running.")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <Button color={'primary'} onClick={() => setOpen(true)}
                    label={"Manual Update"}>
                <CIcon/>
            </Button>
            <Dialog open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Trigger Manual Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to trigger manual update? Please be advised that running updates frequently
                        can cause problem with Youtube api.
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <MaterialButton disabled={loading}
                                    variant={'text'} color={'primary'}
                                    onClick={() => {
                                        setOpen(false)
                                    }}>
                        No
                    </MaterialButton>
                    <MaterialButton disabled={loading} variant={'text'}
                                    color={'primary'}
                                    onClick={saveConfig}>
                        {loading && (
                            <CircularProgress size={25} thickness={2}/>
                        )}
                        Yes
                    </MaterialButton>
                </DialogActions>
            </Dialog>
            <Snackbar open={message.length > 0} message={message} autoHideDuration={5000}
                      onClose={() => setMessage("")}/>
        </Fragment>
    );
}

const Themed = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <ManualUpdateButton {...props}/>
    </MuiThemeProvider>
)
export default Themed;