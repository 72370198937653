import React from "react";
import CIcon from "@material-ui/icons/Notifications";

import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Create,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    Filter,
    List,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
    Toolbar,
} from 'react-admin';
import {PostPagination} from "./Channel";
import {TimeField} from "./common/TimeField";

export const NotificationIcon = CIcon;

const Filters = (props) => (
    <Filter {...props}>
        <TextInput source={"name"} label={"Search"} alwaysOn/>
    </Filter>
);
const LogFilters = (props) => (
    <Filter {...props}>
        <TextInput source={"topic"} label={"Search"} alwaysOn/>
        <SelectInput source={'status'} choices={[{id: 'FAIL', name: "Failed"}, {id: 'SUCCESS', name: 'Success'}]}
                     allowEmpty alwaysOn/>
    </Filter>
);


const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

export const NotificationList = (props) => (
    <List {...props} filters={<Filters/>}
          perPage={25}
          bulkActionButtons={false} pagination={<PostPagination/>}>
        <Datagrid>
            <ReferenceArrayField label="Countries" reference="countries" source="countries">
                <SingleFieldList>
                    <TextField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceField reference={'languages'} source={'language'}>
                <TextField source={'name'}/>
            </ReferenceField>
            <TimeField source={"time"}/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const NotificationCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const NotificationLogList = props => (
    <List {...props} filters={<LogFilters/>}>
        <Datagrid>
            <TextField source={'topic'}/>
            <TextField source={'videos'}/>
            <TextField source={'status'}/>
            <DateField source={'time'} showTime/>
        </Datagrid>
    </List>
)

const CreateOrEdit = (props) => (
    <SimpleForm {...props} toolbar={<EditToolbar/>} redirect={'list'}>
        <TextInput source={"videoId"} helperText={"Input youtube video id or video url. (Optional)"}/>
        <ReferenceInput source="language" reference="languages"
                        label={"Language"}
                        filterToQuery={text => ({name: text})}>
            <AutocompleteInput source={"name"}
                               helperText={'If sending to specific language then youtube video id or video url is compulsory'}
                               optionText={"name"}/>
        </ReferenceInput>
        <ReferenceArrayInput source="countries" reference="countries"
                             label={"Countries"}
                             helperText={'If language selected then this field will have no effect'}
                             filterToQuery={text => ({name: text})}>
            <AutocompleteArrayInput source={"name"} optionText={"name"}/>
        </ReferenceArrayInput>
    </SimpleForm>
);