import * as React from 'react';
import {cloneElement} from 'react';
import {sanitizeListRestProps, TopToolbar, translate,} from 'react-admin';
import ManualStartButton from "./ManualStartButton";

export const DataUpdateListActions = translate(({
                                                    currentSort,
                                                    className,
                                                    resource,
                                                    filters,
                                                    displayedFilters,
                                                    exporter,
                                                    filterValues,
                                                    permanentFilter,
                                                    hasCreate,
                                                    basePath,
                                                    selectedIds,
                                                    onUnselectItems,
                                                    showFilter,
                                                    maxResults,
                                                    total,
                                                    translate,
                                                    excelExporter,
                                                    pdfExportPath,
                                                    excelExportPath,
                                                    ...rest
                                                }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <ManualStartButton/>
    </TopToolbar>
));

