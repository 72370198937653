import React from "react";
import CIcon from "@material-ui/icons/LocationCity";
import LIcon from "@material-ui/icons/Translate";
import RIcon from "@material-ui/icons/Language";

import {
    ArrayInput,
    AutocompleteInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput,
    Toolbar,
} from 'react-admin';
import {PostPagination} from "./Channel";

export const CountryIcon = CIcon;
export const LanguageIcon = LIcon;
export const RegionIcon = RIcon;

const Filters = (props) => (
    <Filter {...props}>
        <TextInput source={"name"} label={"Search"} alwaysOn/>
    </Filter>
);


const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
        />
    </Toolbar>
);

export const CountryList = (props) => (
    <List {...props} filters={<Filters/>}
          perPage={25}
          bulkActionButtons={false} pagination={<PostPagination/>}>
        <Datagrid>
            <TextField source={"name"}/>
            {props.resource === "countries" && <TextField source={"code"}/>}
            {props.resource === "languages" && <TextField source={"code"}/>}
            {props.resource === "countries" && <ReferenceField source={"timezoneId"} reference={"timezones"}>
                <TextField source={"name"}/>
            </ReferenceField>}
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const CountryCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);
export const CountryEdit = (props) => (
    <Edit {...props} >
        <CreateOrEdit/>
    </Edit>
);
const CreateOrEdit = (props) => (
    <SimpleForm {...props} toolbar={<EditToolbar/>}>
        <TextInput source={"name"} validate={required()}/>
        {props.resource === "countries" && <TextInput source={"code"} validate={required()}/>}
        {props.resource === "languages" &&
        <TextInput source={"code"} validate={required()} helperText={"ISO 639-2 Code"}/>}
        {props.resource === "countries" &&
        <ReferenceInput source={"timezoneId"} reference={"timezones"} validate={required()}>
            <SelectInput optionText={"name"}/>
        </ReferenceInput>}
        <ArrayInput source="translations">
            <SimpleFormIterator>
                <ReferenceInput source={"language"} reference={"languages"}
                                filterToQuery={text => ({name: text})}
                                label={"Language"}
                                validate={required()}>
                    <AutocompleteInput optionText={"name"}/>
                </ReferenceInput>}
                <TextInput source="value"
                           label={"Value"}
                           validate={required()}/>
            </SimpleFormIterator>
        </ArrayInput>

    </SimpleForm>
);