import {AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT} from 'react-admin';
import {SERVER_IP} from "../index";
import decodeJwt from 'jwt-decode';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const {username, password} = params;
        let url = `${SERVER_IP}/users/login?username=${username}&password=${password}`;
        return fetch(url, {method: "POST"})
            .then(res => {
                if (res.status < 200 || res.status >= 300) {
                    throw new Error("login.loginFailed");
                }
                return res.json()

            }).then(({accessToken}) => {
                localStorage.setItem('token', accessToken);
                return Promise.resolve();
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const {status} = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() :
            Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const accessToken = localStorage.getItem('token');
        if (accessToken !== null) {
            const role = decodeJwt(accessToken).authority;
            return role ? Promise.resolve(role) : Promise.reject();
        }
        return Promise.reject();
    }
    return Promise.reject();
}