import React from "react";
import {BooleanField, BooleanInput, Datagrid, Filter, List, TextField, TextInput,} from 'react-admin';
import {PostPagination} from "./Channel";
import Icon from '@material-ui/icons/DataUsage';

export const UserDataIcon = Icon;
const Filters = props => (
    <Filter {...props}>
        <TextInput source="country" alwaysOn/>
        <TextInput source="language" alwaysOn/>
        <BooleanInput source={'notificationEnabled'}/>
        <BooleanInput source={'favorite'}/>
    </Filter>
);
export const UserDataList = props => (
    <List {...props} bulkActionButtons={false}
          perPage={25}
          pagination={<PostPagination/>}
          filters={<Filters/>}>
        <Datagrid>
            <TextField source={'country'}/>
            <TextField source={'language'}/>
            <BooleanField source={'notificationEnabled'}/>
        </Datagrid>
    </List>
);


